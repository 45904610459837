import { bindActionCreators } from 'redux'
import * as Abuse from 'services/abuse/actions'
import * as AdyenActions from 'services/adyen/actions'
import * as AlertBarActions from 'services/alert-bar/actions'
import * as AmbassadorsActions from 'services/ambassadors/actions'
import * as AppActions from 'services/app/actions'
import * as Assets from 'services/assets/actions'
import * as AuthActions from 'services/auth/actions'
import * as CheckoutActions from 'services/checkout/actions'
import * as CommentActions from 'services/comments/actions'
import * as ContinueWatchingActions from 'services/continue-watching/actions'
import * as CookieActions from 'services/cookie/actions'
import * as DetailActions from 'services/detail/actions'
import * as DialogActions from 'services/dialog/actions'
import * as EmailSignupActions from 'services/email-signup/actions'
import * as EventTrackingActions from 'services/event-tracking/actions'
import * as ExternalAppActions from 'services/external-app/actions'
import * as FeatureTrackingActions from 'services/feature-tracking/actions'
import * as FilterSetActions from 'services/filter-set/actions'
import * as FormSubmissionsActions from 'services/form-submissions/actions'
import * as GiftActions from 'services/gift/actions'
import * as GuidesActions from 'services/guides/actions'
import * as GuideDaysActions from 'services/guide-days/actions'
import * as HeaderActions from 'components/Header/actions'
import * as HideWatchedActions from 'services/hide-watched/actions'
import * as HiddenContentPreferencesActions from 'services/hidden-contentthe function metadata of `source` into `data`.
 *
 * Merging metadata reduces the number of wrappers used to invoke a function.
 * This is possible because methods like `_.bind`, `_.curry`, and `_.partial`
 * may be applied regardless of execution order. Methods like `_.ary` and
 * `_.rearg` modify function arguments, making the order in which they are
 * executed important, preventing the merging of metadata. However, we make
 * an exception for a safe combined case where curried functions have `_.ary`
 * and or `_.rearg` applied.
 *
 * @private
 * @param {Array} data The destination metadata.
 * @param {Array} source The source metadata.
 * @returns {Array} Returns `data`.
 */
function mergeData(data, source) {
  var bitmask = data[1],
      srcBitmask = source[1],
      newBitmask = bitmask | srcBitmask,
      isCommon = newBitmask < (WRAP_BIND_FLAG | WRAP_BIND_KEY_FLAG | WRAP_ARY_FLAG);

  var isCombo =
    ((srcBitmask == WRAP_ARY_FLAG) && (bitmask == WRAP_CURRY_FLAG)) ||
    ((srcBitmask == WRAP_ARY_FLAG) && (bitmask == WRAP_REARG_FLAG) && (data[7].length <= source[8])) ||
    ((srcBitmask == (WRAP_ARY_FLAG | WRAP_REARG_FLAG)) && (source[7].length <= source[8]) && (bitmask == WRAP_CURRY_FLAG));

  // Exit early if metadata can't be merged.
  if (!(isCommon || isCombo)) {
    return data;
  }
  // Use source `thisArg` if available.
  if (srcBitmask & WRAP_BIND_FLAG) {
    data[2] = source[2];
    // Set when currying a bound function.
    newBitmask |= bitmask & WRAP_BIND_FLAG ? 0 : WRAP_CURRY_BOUND_FLAG;
  }
  // Compose partial arguments.
  var value = source[3];
  if (value) {
    var partials = data[3];
    data[3] = partials ? composeArgs(partials, value, source[4]) : value;
    data[4] = partials ? replaceHolders(data[3], PLACEHOLDER) : source[4];
  }
  // Compose partial right arguments.
  value = source[5];
  if (value) {
    partials = data[5];
    data[5] = partials ? composeArgsRight(partials, value, source[6]) : value;
    data[6] = partials ? replaceHolders(data[5], PLACEHOLDER) : source[6];
  }
  // Use source `argPos` if available.
  value = source[7];
  if (value) {
    data[7] = value;
  }
  // Use source `ary` if it's smaller.
  if (srcBitmask & WRAP_ARY_FLAG) {
    data[8] = data[8] == null ? source[8] : nativeMin(data[8], source[8]);
  }
  // Use source `arity` if one is not provided.
  if (data[9] == null) {
    data[9] = source[9];
  }
  // Use source `func` and merge bitmasks.
  data[0] = source[0];
  data[1] = newBitmask;

  return data;
}

module.exports = mergeData;
